import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/33cbcb6e/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "konfiguration"
    }}>{`Konfiguration`}</h1>
    <p>{`Sie können NeoAnalytics durch das hinzufügen von `}<inlineCode parentName="p">{`data-*`}</inlineCode>{` attributen am `}<inlineCode parentName="p">{`script`}</inlineCode>{` Tag konfigurieren.`}</p>
    <p>{`Um eine Konfiguration zu nutzen müssen Sie als erstes das `}<inlineCode parentName="p">{`data-neo`}</inlineCode>{` Attribut zum script Tag hinzufügen. Anschließend stehen ihnen folgende Optionen zur Verfügung.`}</p>
    <h3 {...{
      "id": "hostname"
    }}>{`Hostname`}</h3>
    <p>{`Sie können NeoAnalytics konfigurieren, eine selbstgewählte, statt die aktuelle domain für das registrieren von Events zu nutzen. Hierzu können Sie ihre gewünschte Domain mit dem `}<inlineCode parentName="p">{`data-hostname`}</inlineCode>{` attribute spezifizieren. Wollen Sie beispielsweise auch den Traffic auf `}<inlineCode parentName="p">{`unterseite.domain.de`}</inlineCode>{` in Ihrem Dashbaord für `}<inlineCode parentName="p">{`domain.de`}</inlineCode>{` sehen, so konfigurieren Sie das script Tag wie folgt.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-HTML"
      }}>{`<script async defer data-neo data-hostname="domain.de" src="https://app.neoanalytics.de/neoClient.js"></script>
`}</code></pre>
    <h3 {...{
      "id": "hash-navigation"
    }}>{`Hash Navigation`}</h3>
    <p>{`Sollte Ihre Website auf Hashnavigation setzen, so können sie NeoAnalytics mit dem Attribut `}<inlineCode parentName="p">{`data-hashnavigation`}</inlineCode>{` in den hashmodus versetzen.`}</p>
    <p>{`Nun erkennt NeoAnalytics Änderungen des Hashes als Navigation zu einer neuen Seite, statt Navigation auf einer Seite und wir für jede Änderung ein entsprechendes Event registrieren.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-HTML"
      }}>{`<script async defer data-neo data-hashnavigation src="https://app.neoanalytics.de/neoClient.js"></script>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      